<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12 class="overlay">
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Exam Routine

            <v-spacer></v-spacer>
            <add-button :permission=true @action="routineNotesModal = true, setRoutineNoteValue()" :disabled="!exam_id ? true : false"> Routine Notes
            </add-button>
            <download-button v-if="form.items.data.length" @action="printRoutine()">Print Routine
            </download-button>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs5>
                <v-select :items="exams" class="pa-0" label="Exam" v-model="exam_id" outlined dense />
              </v-flex>
              <v-flex xs3>
                <v-select :loading="loadingGrade" :disabled="!grades.length" :items="grades" class="pa-0" label="Grade"
                  v-model="grade" outlined dense />
              </v-flex>
              <v-flex xs2 style="margin-bottom:24px" v-if="$auth.isAdmin() && grade">
                <!-- v-if="$auth.isAdmin() && grade && form.is_update" -->
                <v-btn outlined color="indigo" @click="getPreviousunMarkedSubject()">
                  Pull Subject
                </v-btn>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table :headers="headers" hide-default-footer :options.sync="pagination" :items="form.items.data"
            :loading="form.loading">
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  <strong>{{ item.subject.name | capitalize }}</strong> <br />
                  <small style="color:#555">
                    <span style="color:red;" v-if="
                      /शनिबार/.test(
                        getDateFormat(form.items.data[index].exam_date)
                      )
                    ">
                      {{ getDateFormat(form.items.data[index].exam_date) }}
                    </span>
                    <span v-else>
                      {{ getDateFormat(form.items.data[index].exam_date) }}
                    </span>
                  </small>
                </td>
                <td class="text-xs-left">
                  <br />
                  <div style="width: 150px;">
                    <v-calendar-field :disabled="is_publish" @onChange="updateState = true"
                      v-model="form.items.data[index].exam_date" :id-val="'start_date_' + index"
                      label="Exam Date"></v-calendar-field>
                  </div>
                </td>
                <td class="text-xs-left">
                  <div style="display: flex">
                    <input @focus="$event.target.select()" class="marks_entry" style="width: 53px;margin-right: 5px;"
                      autocomplete="off" v-model="form.items.data[index].start_time" :disabled="is_publish" @keyup="
                        (updateState = true),
                        changeStartTime(
                          form.items.data[index].start_time,
                          index
                        )
                        " v-mask="'##:##'" />
                    <select @change="
                      (updateState = true),
                      changeAP(
                        form.items.data[index].st_ampm,
                        index,
                        'start'
                      )
                      " :items="ampm" class="marks_entry" style="width: 39px;padding-left: 9px;" :disabled="is_publish"
                      v-model="form.items.data[index].st_ampm">
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </td>
                <td class="text-xs-right">
                  <div style="display: flex">
                    <input @focus="$event.target.select()" class="marks_entry" style="width: 53px;margin-right: 5px;"
                      autocomplete="off" v-model="form.items.data[index].end_time" :disabled="is_publish" @keyup="
                        (updateState = true),
                        changeEndTime(form.items.data[index].end_time, index)
                        " v-mask="'##:##'" />
                    <select @change="
                      (updateState = true),
                      changeAP(form.items.data[index].et_ampm, index, 'end')
                      " class="marks_entry" style="width: 39px;padding-left: 9px;" :disabled="is_publish"
                      v-model="form.items.data[index].et_ampm">
                      <option value="AM" selected>AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </td>
                <td class="text-xs-right" v-if="
                  form.items.data.length &&
                  $auth.can('routine-create') &&
                  $auth.can('routine-update') &&
                  !is_publish && item.id !== null
                ">
                  <delete-button :permission="true" @agree="deleteRoutine(item)" />
                </td>
              </tr>
            </template>

            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
          <v-card-title v-if="form.items.data.length" class="update-top">
            <v-btn :outlined="form.is_update" :loading="saving" large block :disabled="!updateState || saving" v-if="
              form.items.data.length &&
              $auth.can('routine-create') &&
              $auth.can('routine-update') &&
              !is_publish
            " :color="form.is_update ? 'warning' : 'primary'" @click="save">
              {{ form.is_update ? "Update" : "Save" }}
            </v-btn>
          </v-card-title>
        </v-card>

        <div :class="'inner-overlay ' + (!disabled_alert ? 'dont-show' : '')">
          <p>
            <v-icon style="font-size: 50px;color:red;">warning</v-icon>
            <br />
            Update Routine Disabled. <br />
            <span style="font-size: 15px;">
              Exam has already been published therefore you cannot modify any
              previous record for this Exam / Grade.
              <a href="" @click.prevent="disabled_alert = false" title="I just want to see the records">Close</a>
            </span>
          </p>
        </div>
      </v-flex>
      <v-dialog v-model="form.showDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-select v-model="selectedSubject" :items="filteredSubjects" label="Select Subject" dense outlined
              multiple />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="
                (form.showDialog = false),
                form.reset(),
                $refs.form ? $refs.form.reset() : ''
                ">Close</v-btn>
              <v-btn color="success" text @click="saveUnmarkedSubject"
                :disabled="selectedSubject.length == 0">Save</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Routine Notes -->
      <v-dialog v-model="routineNotesModal" persistent max-width="500px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-form ref="examRoutineNoteForm" @submit.prevent="save"
              @keydown.native="examRoutineNoteForm.errors.clear($event.target.name)" @keyup.enter="save"
              lazy-validation>
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <br>
                  <br>
                  <v-textarea outlined name="primary_note" label="Primary Note *"
                    v-model="examRoutineNoteForm.primary_note" required
                    :error-messages="examRoutineNoteForm.errors.get('primary_note')"
                    :rules="[(v) => !!v || 'Primary Note is required']"
                  >
                  </v-textarea>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-textarea outlined name="secondary_note" label="Secondary Note"
                    v-model="examRoutineNoteForm.secondary_note"></v-textarea>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-list-item>
                    <v-checkbox style="margin-right: 20px" color="blue" v-model="examRoutineNoteForm.is_active">
                      <template v-slot:label>
                        <div style="color: #333">
                          Display Note
                          <p style="line-height: 10px">
                            <small style="color: #777; font-size: 12px; font-weight: normal">
                              When unchecked the notes will not get printed on routine
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-list-item>
                </v-flex>

              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" outlined
              @click="routineNotesModal = false">Close</v-btn>
            <v-btn color="success" outlined @click="saveRoutineNotes">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <!-- Routine Notes End -->
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";
import { passDataToExternalDomain, redirectDownloadUrl } from '../../../../library/helpers';

export default {
  mixins: [Mixins],

  data: () => ({
    printUrl: "",
    filteredSubjects: [],
    selectedSubject: [],
    updateState: false,
    saving: false,
    deleting: false,
    unmarkedSubject: [],
    routineNotesModal: false,
    form: new Form(
      {
        showDialog: false,
        exam_id: "",
        grade_id: "",
        routines: "",
        is_update: false,
      },
      "/api/exam-routine"
    ),
    examRoutineNoteForm: new Form({
      primary_note: '',
      secondary_note: '',
      exam_id: '',
      is_active: false,
    }, "/api/exam-routine-notes"),
    search: null,
    startTimeDialog: false,
    endTimeDialog: false,
    pagination: {
      itemsPerPage: 20,
    },
    rowsPerPageItems: [5, 10, 15, 20],
    subject: "",
    exam_id: "",
    exams: [],
    grade: "",
    is_publish: false,
    disabled_alert: false,
    grades: [],
    loadingGrade: false,
    is_final: false,
    headers: [
      { text: "SN", align: "center", value: "id", width: 50, sortable: false },
      {
        text: "Subject",
        align: "left",
        value: "subject",
        sortable: false,
        width: 300,
      },
      {
        text: "Exam Date",
        align: "centre",
        value: "exam_date",
        width: 230,
        sortable: false,
      },
      {
        text: "Exam Start Time",
        align: "centre",
        value: "end_time",
        sortable: false,
        width: 150,
      },
      {
        text: "Exam End Time",
        align: "centre",
        value: "end_time",
        sortable: false,
        width: 150,
      },
      {
        text: "Action",
        align: "centre",
        value: "end_time",
        sortable: false,
        width: 50,
      },
    ],
    ampm: [
      { value: "AM", text: "AM" },
      { value: "PM", text: "PM" },
    ],
  }),
  computed: {
    ...mapState(["batch", "user"]),
  },
  mounted() {
    this.getExams();
  },
  watch: {
    grade: function () {
      this.get();
    },
    exam_id: function (v) {
      this.grade = "";
      this.form.items.data = [];
      this.getGrades();
      this.getRoutineNote();
    },
  },

  methods: {
    saveUnmarkedSubject() {
      if (this.selectedSubject.length > 0) {
        this.$rest
          .post("/api/save-unmarked-subject", {
            subject: this.selectedSubject,
            grade_id: this.grade,
            exam_id: this.exam_id,
          })
          .then((res) => {
            if (res.status == 200) {
              this.form.showDialog = false;
              this.$events.fire("notification", {
                message: res.data.message,
                status: "success",
              });
              this.get();
            }
          })
          .catch((e) => {
            if (e.response)
              this.$events.fire("notification", {
                message: e.response.data.message,
                status: "error",
              });
          });
      } else {
        this.$events.fire("notification", {
          message: "Select At least one subject",
          status: "error",
        });
      }
    },
    getPreviousunMarkedSubject() {
      this.getAllSubjects(null, this.grade);
    },
    // getPerviousunMarkedSubject(params){
    //   this.form.showDialog = true;
    //   let extraParams = "batch= " + "&grade=" + this.grade;
    //    let query = [null, undefined].includes(params)
    //     ? this.queryString(extraParams)
    //     : params;
    //   this.$rest.get('/api/subject?'+query)
    //   .then((response)=>{
    //     let allSubjects = response.data.data
    //     let filteredArray = allSubjects.filter((elem) => !this.form.items.data.find(({ subject_id }) => elem.id === subject_id));
    //      this.filteredSubjects = filteredArray.map((item) => {
    //         return { value: item.id, text: item.name };
    //     });
    //   })
    //   .catch((error)=>{

    //   })
    // },
    getDateFormat(date) {
      try {
        let dateInstance = new NepaliDate(date.dateForm());
        return dateInstance.format("mmmm d, yyyy dddd");
      } catch (e) {
        return "-";
      }
    },

    get(params) {
      if (this.exam_id && this.$auth.can("routine-read") && this.grade) {
        let extraParams =
          "&batchId=" +
          this.batch.id +
          "&gradeId=" +
          this.grade +
          "&examId=" +
          this.exam_id;
        this.form.items.data = [];
        this.form.get(null, this.queryString(extraParams)).then(({ data }) => {
          this.is_publish = data.isPublished;
          this.disabled_alert = data.isPublished;
          this.form.is_update = data.is_update;
          this.printUrl = data.print_url;
          this.form.items.data = this.form.items.data.map((res) => {
            if (!res.st_ampm) {
              res.st_ampm = "AM";
            }
            if (!res.et_ampm) {
              res.et_ampm = "PM";
            }
            if (!res.exam_date) {
              res.exam_date = nd.format("YYYY-MM");
            }
            return res;
          });
        });
      }
    },
   

    save() {
      this.form.grade_id = this.grade;
      this.form.exam_id = this.exam_id;
      this.saving = true;
      this.form.routines = this.form.items.data.map((r) => {
        r.exam_date = r.exam_date.dateForm();
        return r;
      });
      this.form.fireFetch = false;
      this.form
        .store()
        .then(() => {
          this.updateState = false;
          this.form.is_update = true;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    deleteRoutine(item) {
      this.$rest
        .delete("/api/exam-routine/" + item.id, {
          data: {
            exam_id: this.exam_id,
            grade_id: this.grade,
            subject_id: item.subject_id,
          },
        })
        .then((res) => {
          if (res.status == 200)
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
          this.get();
        })
        .catch((e) => {
          if (e.response)
            this.$events.fire("notification", {
              message: e.response.data.message,
              status: "error",
            });
        });
    },

    getGrades() {
      this.loadingGrade = true;
      this.$rest
        .get(
          "/api/exam-grade?rowsPerPage=25&sortBy=rank&descending=true&slim=true&examId=" +
          this.exam_id
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.grade_id, text: item.name };
          });
        })
        .finally(() => {
          this.loadingGrade = false;
        });
    },

    getExams() {
      this.$rest.get("/api/exam").then(({ data }) => {
        this.exams = data.data.map((item) => {
          return { value: item.id, text: item.name };
        });
      });
    },

    editData(subject) {
      this.subject = subject;
      this.form.exam_date = nd.format("YYYY-MM-DD");
    },

    changeStartTime(value, index) {
      if (index == 0) {
        this.form.items.data.map((res) => {
          res.start_time = value;
        });
      }
    },

    changeEndTime(value, index) {
      if (index == 0) {
        this.form.items.data.map((res) => {
          res.end_time = value;
        });
      }
    },

    changeAP(value, index, type = "start") {
      if (index === 0) {
        switch (type) {
          case "start":
            this.form.items.data.map((res) => {
              res.st_ampm = value;
            });
            break;
          case "end":
            this.form.items.data.map((res) => {
              res.et_ampm = value;
            });
            break;
        }
      }
    },
    printRoutine() {
      passDataToExternalDomain('reporting')
      var exam_name = this.exams.find(v => v.value == this.exam_id).text
      var grade_name = this.grades.find(v => v.value == this.grade).text
      setTimeout(() => {
        const url = redirectDownloadUrl({ uri: '/print/exam/exam-routine', queryString: `gradeId=${this.grade}&examId=${this.exam_id}&exam_name=${exam_name}&grade_name=${grade_name}` });
        // window.open(url)  
      }, 200);
    },

    saveRoutineNotes() {
      this.examRoutineNoteForm.exam_id = this.exam_id;
      if (this.$refs.examRoutineNoteForm.validate()) {
        this.examRoutineNoteForm.store().then(() => {
          this.routineNotesModal = false; 
          // this.getRoutineNote()
        });
      }
    },
    setRoutineNoteValue(){
      if(!this.examRoutineNoteForm.primary_note) {
       this.getRoutineNote();
      }
    },
    getRoutineNote(){
      if (this.exam_id) {
        let extraParams =
          "&batchId=" +
          this.batch.id +
          "&examId=" +
          this.exam_id;
          this.examRoutineNoteForm.get(null, this.queryString(extraParams)).then(({ data }) => {
            this.examRoutineNoteForm.primary_note = data.primary_note
            this.examRoutineNoteForm.secondary_note = data.secondary_note,
            this.examRoutineNoteForm.is_active = data.is_active === 0 ? false : true
          });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.marks_entry {
  border: 1px solid #ccc;
  font-weight: bold;
  border-radius: 3px;
  width: 142px;
  height: 25px !important;
  text-align: center !important;
}

input[disabled],
select[disabled] {
  background-color: #eee;
  color: #ccc;
  cursor: not-allowed;
}

.theme--light.v-select .v-select__selections {
  margin-top: -27px !important;
  margin-left: 8px !important;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

.update-top {
  margin-top: 50px;
}
</style>
